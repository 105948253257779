#navbarr {
  border-style: ridge;
  border-color: whitesmoke;
  display: flex;
  flex-wrap: wrap;
}

#span-logo {
  font-weight: bold;
}

button.submit-btn {
  padding: 6px 12px;
  border: 1px solid;
  margin: 5px;
}

button.submit-btn:hover {
  background-color: chartreuse;
}

.drop-down {
  border: 1px solid green;
  padding: 6px 12px;
  height: 70px;
  overflow: scroll;
  margin-top: 5px;
}

.input-search {
  padding: 6px 12px;
  width: 100%;
}

@media only screen and (max-width: 559px) {
  #blocker {
    display: none;
  }
}
