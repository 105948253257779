.Home {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  background-image: url("./images/bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

h1 {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 2.5em;
}

#dropdown {
  border-radius: 5px;
  margin-bottom: 10px;
}
